import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import { StyledHeading } from '../components/page-elements'
import { Offers } from '../components/dutchie/Menu'

const HeroContainer = styled.div`
  position: relative;
  display: grid;
  height: 50vh;
`
const FakeBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.05) 70%, rgba(0,0,0,0.15) 100%);
`
const Overlay = styled.div`
  position: relative;
  grid-area: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.25);
`
const SuperHeading = styled(StyledHeading)`
  position: relative;
  background: hsla(${({theme}) => theme.teal.hsl}, 1);
  color: hsla(${({theme}) => theme.darkGray.hsl}, 1);
  mix-blend-mode: lighten;
  text-transform: uppercase;
  font-size: 3.052rem;

  @media(min-width: 768px) {
    font-size: 4.768rem;
  }
  @media(min-width: 1024px) {
    font-size: 5.96rem;
  }  
`

const Deals = ({ location }) => {
  return (
    <Layout
      title="Deals"
      pathname={location.pathname}
    >
      <HeroContainer>
        <StaticImage
          src="../images/stock-img-forest-lake.jpg"
          alt="Stream running through a forest"
          placeholder="blurred"
          objectPosition="center 25%"
          transformOptions={{
            duotone: {
              highlight: '#80fce3',
              shadow: '#800080',
              opacity: 55,
            }
          }}
          style={{
            gridArea: '1/1',
          }}
        />
        <Overlay>
            <FakeBg />
            <SuperHeading>Deals</SuperHeading>
        </Overlay>
      </HeroContainer>
      <Offers />
    </Layout>
  )
}

export default Deals
