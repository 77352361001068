import React from 'react'
import { Helmet } from 'react-helmet'

/* Main Dutchie menu script */
export const Menu = () => {

  return (
    <div id="dutchie--embed__script">
      <Helmet>          
        <script async="" src="https://dutchie.com/api/v2/embedded-menu/6101936d5e63b100bd2cb3bb.js"></script>
      </Helmet>
    </div>
  )
}

/* 
  Offers Menu
  Embed script is configured in Dutchie > Settings > Customize
  Requires URL of page this menu is used on to be hard coded in Dutchie UI
*/
export const Offers = () => {

  return (
    <div id="dutchie--carousel-embed-ca26e5ce-a2d2-4f6c-9141-8ae7472e2a45__script">
      <Helmet>
        <script async="" src="https://dutchie.com/api/v3/embedded-menu/6101936d5e63b100bd2cb3bb/carousels/ca26e5ce-a2d2-4f6c-9141-8ae7472e2a45.js?routeRoot=https%3A%2F%2Feastoncraft.com%2Fdeals&hideTitle=true&hideLink=true"></script>        
      </Helmet>
    </div>
  )
}